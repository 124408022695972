@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f7fdfe;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.card .content.SESS {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(255, 0, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.SAFT {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(0, 255, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.SCRE {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(0, 0, 255, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.PT {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(255, 251, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content div.inline {
  float: left;
}

.details-view .header.SESS-modal-header {
  padding-top: 0.2em;
  background-color: rgba(255, 0, 0, 0.6);
}

.details-view .header.SAFT-modal-header {
  padding-top: 0.2em;
  background-color: rgba(0, 255, 0, 0.6);
}

.details-view .header.SCRE-modal-header {
  padding-top: 0.2em;
  background-color: rgba(0, 0, 255, 0.6);
}

.ui.modal .STG_MODEL_GWAC.edit-asset-header {
  background-color: rgba(0, 212, 53, 0.6);
}

.iconButton {
  padding: 10;
}

.input {
  flex: 1 1;
}

.paper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  /* width: 400; */
}

.SESS {
  padding-top: 0.2em;
  background-color: rgba(255, 0, 0, 0.6);
}

.SAFT {
  padding-top: 0.2em;
  background-color: rgba(0, 255, 0, 0.6);
}

.SCRE {
  padding-top: 0.2em;
  background-color: rgba(0, 0, 255, 0.6);
}

.PT {
  padding-top: 0.2em;
  background-color: rgba(255, 251, 0, 0.6);
}

.dialogTitleText {
  color: white !important;
  font-size: 1.5rem !important;
  font-family: poppins-semibold, poppins, sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

.blueLink {
  cursor: pointer;
  color: blue;
}

.bluelink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.asset-status {
  float: left;
  color: black;
  border-radius: 2px;
  font-size: 0.9em;
  padding: 5px;
}

.ui.menu .item > i.icon.pdf {
  color: red;
}

.tab embed[type='application/pdf'] {
  height: 600px;
  width: 100%;
}

/* loader classes needed to address known issue when loader is in a modal
https://github.com/Semantic-Org/Semantic-UI-React/issues/3133 */
.ui.dimmer .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.loader:after {
  border-color: #767676 transparent transparent;
}

.ui.dimmer .ui.loader > div {
  color: black;
}

.ui.labeled.loading-ids.button > .icon {
  background-color: unset;
}

.ui.active.selection.button:hover {
  background-color: unset;
}

.ui.selection.button {
  font-weight: unset;
}

.saved-search-term-label {
  padding-bottom: 1em;
}

.skip-filter-bar {
  height: 30px;
  left: 50%;

  position: absolute;
  -webkit-transform: translateY(-1200%);
          transform: translateY(-1200%);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.skip-filter-bar:focus {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  padding: 3px;
  background-color: #ffffff;
}

.ui.modal .save-search-header {
  background-color: rgb(0, 107, 181);
  color: rgb(255, 255, 255, 0.85);
}

.search-terms-div {
  padding-bottom: 1em;
}

.save-search-actions {
  background: #f9fafb;
  padding: 0rem 0rem 0rem 0rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}

.button.save-search-button {
  margin-left: 0.75em;
}

.ui.menu.action-bar {
  margin-top: -1em;
}

.ui.menu.action-bar .item {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
}

.ui.menu.action-bar > :first-child {
  padding-left: 0;
}

.ui.menu.action-bar .separator {
  display: inline-block;
  width: 1px;
  height: 1.5rem;
  background-color: #dcdcdc;
}

.results-count {
  margin: auto;
  border: '1pt solid red';
  font-weight: unset;
}

.results-count.value {
  align-items: center;
  display: flex;
}

.RegistrationPage_RegistrationPage__R5IDh {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RegistrationPage_paper__2OGIJ {
  max-width: 450px;
  padding: 20px;
  margin: 0 auto;
}
.RegistrationPage_button__2IjaW {
  margin-top: 10px;
  margin-bottom: 10px;
}

.RegistrationPage_container__1Zcol {
  margin: 0 auto;
}

.details-view.fullscreen {
  width: 100%;
}

.details-view.fullscreen > .header {
  display: block;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 1.25rem 3%;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.details-view.fullscreen > .content {
  max-width: 1200px;
  margin: auto;
  padding: 0 1em;
  padding-top: 1em;
}

.gutter {
  background-color: rgb(96, 96, 97);
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.asset {
  margin: 50px;
}

.FAQs_container__2y3Rz{
    padding:15px
}
.NominateResource_NominateArtifact__bOR_r {
  width: 100%;
}
.NominateResource_dialogTitle__1mP9T {
  background-color: #004b71;
  color: white !important;
}
.NominateResource_dialogTitleText__4hlcY {
  color: white !important;
  font-size: 1.25rem;
  font-family: poppins-semibold, poppins, sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

.ui.modal .STG_MODEL_CPARS.add-asset-header {
  background-color: cyan;
}

.ui.modal .STG_MODEL_GWAC.add-asset-header {
  background-color: rgba(0, 212, 53, 0.6);
}

.add-asset-actions {
  background: #f9fafb;
  padding: 0rem 0rem 0rem 0rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}

.button.add-asset-button {
  margin-left: 0.75em;
}

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: inherit;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 200px;
}

.dropzone-active {
  border-width: 1px;
  border-radius: 1px;
  border-color: #eeeeee;
  border-style: solid;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  width: inherit;
}

.ui.list.file-list {
  margin-top: 10;
}

.remove-file {
  padding: inherit;
}

.table-input {
  width: 100%;
}

.table-values {
  padding-left: 0.5em;
  padding-top: 0.5em;
}

.asset-tags > .react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.asset-tags .react-tagsinput-tag {
  margin-bottom: 0em;
  margin-right: 0em;
  margin-left: 0.2em;
  margin-top: 0.15em;
  border-color: #85b7d9;
  background-color: unset;
  border-radius: 0.28571429rem;
  color: unset;
}

.asset-tags .react-tagsinput--focused {
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  border-radius: 0.28571429rem;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
}

.asset-tags input[type='text'] {
  width: 30% !important;
  border: unset !important;
  font-size: 0.98em !important;
  padding-top: 0.67857143em !important;
  padding-bottom: 0.5em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.select-file {
  float: right;
}

.file-icon {
  padding-top: 5px;
}

.ui.header.filter-header {
  margin-top: 0;
}

#filters {
  padding: 1em;
  padding-bottom: 40px;
  width: 275px;
  flex: 0 0 auto;
}

.filter-content {
  padding-left: 2em;
}

.ui.label.remove-label,
.ui.label.remove-label:hover {
  background-color: #28badbad;
  border-color: #28badbad;
}

.ui.label.undefined-filter-label {
  font-style: italic;
}

.ui.inverted.dimmer.facet-dimmer {
  z-index: 100;
}

.skip-filter {
  height: 30px;
  left: 25%;
  position: absolute;

  -webkit-transform: translateY(-1000%);

          transform: translateY(-1000%);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.skip-filter:focus {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  padding: 3px;
  background-color: #ffffff;
}

/* .ui.form.search-bar {
  min-width: 40vw !important;
}
.ui.form.search-bar-full {
  min-width: 100% !important;
}

.ui.form.search-bar .ui.action,
.ui.form.search-bar .ui.search,
.ui.form.search-bar input {
  width: 100%;
}

.ui.form.search-bar-full .ui.action,
.ui.form.search-bar-full .ui.search,
.ui.form.search-bar-full input {
  width: 100%;
}
.ui.form.search-bar-full input {
  font-size: 1rem;
}

.ui.form.search-bar input {
  font-size: 1rem;
} */

/* .ui.form.search-bar .action:hover {
  background: transparent;
}
.ui.form.search-bar-full .action:hover {
  background: transparent;
} */
/*
.ui.form.search-bar-full .results.transition {
  margin-top: 0;
}
.ui.form.search-bar .results.transition {
  margin-top: 0;
} */

.ui.button.show_more_or_less {
  background: white;
  color: #4183c4;
  border: none;
  border-radius: 0;
  padding-top: 0.1em;
  padding-right: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.1em;
}

.ui.button.show_more_or_less:hover {
  background-color: lightgray;
}

.filter-content.filter-sort {
  padding-bottom: 5px;
}

